import React from 'react'

import firebase from 'firebase/app'
import 'firebase/firestore'

import cookie from 'react-cookies'
import randomString from 'random-string'

import PatternCreator from 'components/PatternCreator'

import './Create.css'

class Create extends React.Component {
  state = {
    view: 'idle'
  }

  componentDidMount(){
    this.db = firebase.database();
  }

  render () {
    return (
      <div className="view">
        <h1>Create</h1>

        <PatternCreator
          view={this.state.view}
          ref="patternCreator"
          onUpload={this.upload.bind(this)}
          onReset={this.reset.bind(this)}
          onRecording={this.setState.bind(this,{view: 'recording'})}
          onRecordingFinish={this.setState.bind(this,{view: 'setName'})}
          style={{width: '100%'}}
        />
      </div>
    )
  }

  async upload(name){
    let pattern = this.refs['patternCreator'].getPattern();

    this.setState({view: 'loading'})

    let collectionId = cookie.load('collectionId');
    let patternId = randomString({length: 20});

    this.db.ref(`collections/${collectionId}/patterns/${patternId}`).set({
      name: name,
      pattern: pattern
    })
    .then(docRef => {
      this.setState({view: 'created'})
    })
    .catch( error => {
      this.setState({view: 'error'})
    });
  }

  reset(){
    this.setState({view: 'idle'})
  }
}

export default Create;
