import React from 'react'

import PatternVisualizer from 'components/PatternVisualizer'
import PatternController from 'components/PatternController'

import './PatternCreator.css'

class PatternCreator extends React.Component {
  componentDidMount(){

    window.onkeydown = e =>{
      if(e.keyCode === 32){
        e.preventDefault();
        this.press()
      }
    }

    window.onkeyup = this.release.bind(this);

    this.patternVisualizer = this.refs['patternVisualizer'];
    this.pattern = [];
  }

  render () {
    return (
      <React.Fragment>

        <PatternVisualizer ref="patternVisualizer"/>

        <PatternController
          view={this.props.view}
          onRecordingFinish={this.onRecordingFinish.bind(this)}
          onUpload={this.props.onUpload}
          onReset={this.reset.bind(this)}
          onPress={this.press.bind(this)}
          onRelease={this.release.bind(this)}
        />

      </React.Fragment>

    )
  }

  press(){
    if(this.pressed || ! (this.props.view === 'idle' || this.props.view === 'recording'))
      return;
    this.pressed = true;

    if(this.props.view === 'idle')
      this.props.onRecording();

    let now = Date.now();
    this.lastPush = now;

    if(this.pattern.length === 0)
      this.patternVisualizer.start();
    else
      this.pattern.push(now - this.lastRelease);

    this.patternVisualizer.press();
  }

  release(e){
    if(!this.pressed)
      return;

    this.pressed = false;
    this.patternVisualizer.release();

    let now = Date.now()
    this.pattern.push(now - this.lastPush);
    this.lastRelease = now;
  }

  getPattern(){
    this.patternVisualizer.stop();
    return this.pattern;
  }

  reset(){
    this.refs['patternVisualizer'].reset();
    this.pattern = [];
    this.props.onReset();
  }

  onRecordingFinish(){
    this.refs['patternVisualizer'].stop();
    this.props.onRecordingFinish();
  }

  componentWillUnmount() {
    window.onkeydown = null;
    window.onkeyup = null;
  }


}

export default PatternCreator;
