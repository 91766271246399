import React from 'react'
import { VolumeUp, Vibration, Visibility } from '@material-ui/icons';

import './PlayTypeToggle.css'

class PlayTypeToggle extends React.Component {

  constructor(){
    super()
    this.vibrationDisabled = !("vibrate" in navigator) || !(window.orientation > -1)
  }

  render () {
    let vibrationclasses = '';
    if(this.props.playType === 'vibrate')
      vibrationclasses += 'active '
    if(this.vibrationDisabled)
      vibrationclasses += 'disabled'
    return (
      <div id="playTypeToggle" className={this.props.playType}>
        <div onClick={this.changePlayType.bind(this, 'audio')} className={(this.props.playType === 'audio') ? 'active' : ''}>
          <VolumeUp/>
          <span>Beep</span>
        </div>

        <div onClick={this.changePlayType.bind(this, 'vibrate')} className={vibrationclasses}>
          <Vibration/>
          <span>Vibrate</span>
        </div>

        <div onClick={this.changePlayType.bind(this, 'blink')} className={(this.props.playType === 'blink') ? 'active' : ''}>
          <Visibility/>
          <span>Blink</span>
        </div>

      </div>
    )
  }

  changePlayType(playType){
    if(this.vibrationDisabled && playType === 'vibrate')
      return
    this.props.changePlayType(playType);
  }
}

export default PlayTypeToggle;
