import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from "react-router-dom";

import Button from 'components/Button'
import Input from 'components/Input'

class PatternController extends React.Component {
  render () {
    let head;
    let buttons;

    if(this.props.view === 'idle' || this.props.view === 'recording'){
      head = <Button
        text="Click to create pattern"
        onTouchStart={this.props.onPress}
        onTouchEnd={this.props.onRelease}
        onMouseDown={this.props.onPress}
        onMouseUp={this.props.onRelease}
      />;
    }

    if (this.props.view === 'recording') {
      buttons =
        <React.Fragment>
          <Button
            onClick={this.props.onRecordingFinish}
            text="Finish"
          />
          <Button
            onClick={this.props.onReset}
            text="reset"
          />
      </React.Fragment>;
    }
    else if(this.props.view === 'setName') {
      head = <h2>Give your pattern a name</h2>;
      buttons = <Input autoFocus onSubmit={this.props.onUpload}/>;
    }
    else if(this.props.view === 'created'){
      head = <h2>What's next?</h2>;
      buttons =
      <React.Fragment>
        <Button
          onClick={this.props.onReset.bind(this)}
          text="Create New"
        />

        <Button
          text={<NavLink to="/play">Play</NavLink>}
        />
      </React.Fragment>
    }
    else if(this.props.view === 'loading'){
      head = <h2>Uploading</h2>
      buttons = <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress style={{color: 'var(--app-color)'}} /></div>;
    }
    else if(this.props.view === 'error'){
      head = <h2>Error</h2>
      buttons = <Button onClick={this.props.onReset} text="reset"/>;
    }

    return (
      <div id="actions">
          {head}
        <div>
          {buttons}
        </div>
      </div>
    )
  }
}

export default PatternController;
