class VibrationPlayer{

  play(pattern){
    navigator.vibrate(pattern);
    let time = pattern.reduce((ges, val) => ges + val);

    this.timeout = setTimeout(e =>{
      this.ended();
    }, time)

    return new Promise((res, rej) =>{
      this.ended = res;
    })
  }

  stop(){
    navigator.vibrate(0);
    clearTimeout(this.timeout);
    this.ended();
  }
}

export default VibrationPlayer;
