import React from 'react'
import Input from 'components/Input'
import {Create} from '@material-ui/icons';

class ChangableName extends React.Component {
  state = {
    edit: false
  }
  render () {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', ...this.props.style}}>
        {
          this.state.edit ?
            <Input
              onSubmit={this.onChange.bind(this)}
              autoFocus
            />
          :
            <React.Fragment>
              <h2 style={{marginRight: '10px', flexShrink: '1', wordBreak: 'break-word', textAlign: 'left'}}>{this.props.name}</h2>
              <Create onClick={this.toggleEdit.bind(this)}/>
            </React.Fragment>
        }
      </div>
    )
  }

  toggleEdit(){
    this.setState({edit: !this.state.edit})
  }

  onChange(name){
    this.toggleEdit();
    this.props.onChange(name)
  }
}

export default ChangableName;
