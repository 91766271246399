import React from 'react'

import SvgIcon from '@material-ui/core/SvgIcon';
import {Description} from '@material-ui/icons';

import ImgButton from 'components/ImgButton'
import Dialog from 'components/Dialog'

import './PatternDownloader.css'

class PatternDownloader extends React.Component {
  render () {
    let arrText = this.props.pattern ? `[${this.props.pattern.toString()}]` : '';
    return (
      <Dialog id="downloadDialog" open={this.props.open} onClose={this.props.onClose} name="Save Pattern">

        <textarea ref="downloadTextBox" readOnly value={arrText}></textarea>

        <div id="downloadButtons">
          <ImgButton
            text="copy to clipboard"
            img={<SvgIcon><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></SvgIcon>}
            onClick={this.copy.bind(this)}
          />
          <ImgButton
            text="download as file"
            img={<Description/>}
            onClick={this.download.bind(this)}
          />
        </div>

      </Dialog>
    )
  }

  download(){
    let arrText = `[${encodeURIComponent(this.props.pattern.toString())}]`;

    let a = document.createElement('a');
    a.style.display = 'none';
    a.setAttribute('href', 'data:text/plain;charset=utf-8,' + arrText);
    a.setAttribute('download', this.props.title);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  copy(){
    this.refs['downloadTextBox'].select();
    document.execCommand('copy');
  }
}

export default PatternDownloader;
