import React from 'react'

import {Create} from '@material-ui/icons'

import Title from 'components/Title'
import NavButton from 'components/NavButton'

import './Home.css'

class Home extends React.Component {
  render () {
    return (
      <div className="view">
        <Title
          title="Home"
          text="Vibrationcreator helps you with creating vibration patterns to play on your phone."
        />

        <div id="startNow">
          <NavButton
            text="Start Now"
            img={<Create/>}
            localurl = "/create"
          />
        </div>
      </div>
    )
  }
}

export default Home;
