import React from 'react';

import { Route, Switch } from "react-router-dom";
import randomString from 'random-string'
import cookie from 'react-cookies'

import Header from 'components/Header';

import Home from 'views/Home'
import Create from  'views/Create';
import Play from  'views/Play';
import Error404 from 'views/Error404';
import Sync from 'views/Sync'

import './App.css';

class App extends React.Component {

  constructor(){
    super();
    if(!cookie.load('collectionId'))
      cookie.save('collectionId', randomString({length: 20}), { path: '/' })
  }

  render(){
    return (
      <div id="app">
        <Header/>
        <div id="content">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/create" component={Create} />
            <Route path="/play" component={Play} />
            <Route path="/sync/:collectionId?" component={Sync} />
            <Route component={Error404} />
          </Switch>
        </div>
      </div>
    )
  }
}

export default App;
