import React from 'react'

import './Button.css'

class Button extends React.Component {
  render () {
    return (
      <button
        onClick={this.props.onClick}
        onTouchStart={this.props.onTouchStart}
        onTouchEnd={this.props.onTouchEnd}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
        className="button"
        >
        {this.props.text}
      </button>
    )
  }
}

export default Button;
