import React from 'react'

import { Link } from "react-router-dom";

import ImgButton from 'components/ImgButton'

import './NavButton.css'

class NavButton extends React.Component {
  render () {
    let insert =
      <ImgButton
        text={this.props.text}
        img={this.props.img}
        isChild={true}
      />;

    if(this.props.url){
      return (
        <a href={this.props.url} className="navButton">
          {insert}
        </a>
      )
    }
    else if(this.props.localurl){
      return (
        <Link to={this.props.localurl} className="navButton">
          {insert}
        </Link>
      )
    }
    else{
      return null;
    }
  }
}

export default NavButton;
