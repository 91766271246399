import React from 'react'

import './Input.css'

class Input extends React.Component {
  render () {
    return (
      <div className="input">
        <input onKeyDown={this.keyDown.bind(this)} autoFocus={this.props.autoFocus} ref="input" type="text" placeholder="name"/>
        <button onClick={this.submit.bind(this)}>Set</button>
      </div>
    )
  }

  submit(){
    this.props.onSubmit(this.refs['input'].value);
  }

  keyDown(e){
    if(e.keyCode === 13)
      this.submit()
  }
}

export default Input;
