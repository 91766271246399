import React from 'react'

import firebase from 'firebase/app'
import 'firebase/database'

import cookie from 'react-cookies'
import { Redirect } from "react-router-dom";

import {Warning} from '@material-ui/icons'

import Title from 'components/Title'
import CollectionStateViewer from 'components/CollectionStateViewer'
import ImgButton from 'components/ImgButton'

class Sync extends React.Component {
  state = {
    newCollection: {
      id: this.props.match.params.collectionId,
      state: 'loading',
    },
    oldCollection: {
      id: cookie.load('collectionId'),
      state: 'loading',
    },
    redirect: false,
  }

  componentDidMount(){
    this.db = firebase.database();
    this.loadCollections();
  }

  render () {
    return (
      <div className="view">

        <Title
          title="Sync"
          text="Synronize this device with a collection to get live updates from this collection"
        />

      <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
          <CollectionStateViewer
            collection={this.state.oldCollection}
            title="current collection"
          />

          <CollectionStateViewer
            collection={this.state.newCollection}
            title="new collection"
          />
        </div>

        {
          this.state.newCollection.state === 'loaded' ?
            <React.Fragment>
              <h2 style={{color: 'var(--text-color)'}}>By using the new collection, you will loose your current one</h2>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <ImgButton
                  text="Use new collection"
                  img={<Warning/>}
                  onClick={this.syncNewCollection.bind(this)}
                />
              </div>
            </React.Fragment>
          :
            <React.Fragment>
              <h2 style={{color: 'var(--text-color)'}}>Error loading the new collection</h2>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <ImgButton
                  text="Retry"
                  img={<Warning/>}
                  onClick={window.location.reload.bind(window.location)}
                />
              </div>
            </React.Fragment>
        }




        {
          this.state.redirect ?
            <Redirect to='/play' />
          :
            null
        }

      </div>
    )
  }

  syncNewCollection(){
    cookie.save('collectionId', this.state.newCollection.id, {path: '/'});
    console.log(this.state.newCollectionId)
    this.setState({redirect: true});
  }

  loadCollections(){

    this.loadCollection(this.state.oldCollection, collection =>{
      this.setState({oldCollection: collection})
    })

    this.loadCollection(this.state.newCollection, collection =>{
      this.setState({newCollection: collection})
    })
  }

  loadCollection(data, callback){
    const query = this.db.ref('collections/'+data.id);

    query.on('value', snapShot =>{
      let rawData = snapShot.val();

      let collection = {
        name: '',
        id: snapShot.key,
        patterns: [],
        state: 'loaded'
      }

      if(rawData){
        collection.name = rawData.name ? rawData.name : 'New Collection';

        for(let id in rawData.patterns){
          collection.patterns.push({
            id: id,
            ...rawData.patterns[id]
          })
        }
      }
      else {
        collection.state = 'empty'
      }

      callback(collection);
    }, e =>{
      let collection = {
        name: '',
        id: null,
        patterns: [],
        state: 'error',
      }
      callback(collection)
    })
  }
}

export default Sync;
