class AudioPlayer{
  init(){
    this.context = window.AudioContext || window.webkitAudioContext;
    this.ctx = new AudioContext();

    this.oscNode = this.ctx.createOscillator();
    this.oscNode.start();
    this.gainNode = this.ctx.createGain();
    this.gainNode.gain.value = 0;

    this.oscNode.connect(this.gainNode);
    this.gainNode.connect(this.ctx.destination);
  }

  async play(pattern){
    if(!this.ctx)
      this.init()
    this.pos = 0;
    this.pattern = pattern;

    this.tick();

    return new Promise((res, rej) =>{
      this.ended = res;
    })
  }

  tick(){
    if(this.pos % 2 === 0)
      this.gainNode.gain.setTargetAtTime(1, this.ctx.currentTime, 0.005);
    else
      this.gainNode.gain.setTargetAtTime(0, this.ctx.currentTime, 0.005);

    if(this.pattern.length <= this.pos)
      this.stop();

    let time = this.pattern[this.pos++];
    this.timeout = setTimeout(this.tick.bind(this), time);
  }

  stop(){
    if(this.timeout){
      clearTimeout(this.timeout);
      this.timeout = null;
      this.gainNode.gain.setTargetAtTime(0, this.ctx.currentTime, 0.005);
      this.ended('resolve');
    }
  }
}

export default AudioPlayer;
