class BlinkPlayer{

  constructor(blinker){
    this.blinker = blinker;
  }

  play(pattern){
    this.pos = 0;
    this.pattern = pattern;

    this.tick();

    return new Promise((res, rej) =>{
      this.ended = res;
    })
  }

  tick(){
    if(this.pos % 2 === 0)
      this.blinker.on();
    else
      this.blinker.off();

    if(this.pattern.length <= this.pos)
      this.stop();

    let time = this.pattern[this.pos++];
    this.timeout = setTimeout(this.tick.bind(this), time);
  }

  stop(){
    clearTimeout(this.timeout);
    this.ended();
    this.blinker.off();
  }
}

export default BlinkPlayer;
