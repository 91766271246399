import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

class Spinner extends React.Component {
  render () {
    return (
      <CircularProgress style={{color: 'var(--app-color)'}}/>
    )
  }
}

export default Spinner;
