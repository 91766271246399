import React from 'react'

import { PlayArrow, Stop, CloudDownload, DeleteForever} from '@material-ui/icons';
import './PlayCard.css'

class PlayCard extends React.Component {

  state = {
    playing: 0,
    mode: '',
    title: this.props.title ? this.props.title : 'new pattern'
  }

  render () {
    let playButton;
    if(this.state.playing)
      playButton = <Stop onClick={this.stop.bind(this)}/>;
    else
      playButton = <PlayArrow onClick={this.play.bind(this)}/>;

    return (
      <div className="playCard">
        <h3>{this.state.title}</h3>
        <div>
          {playButton}
          <CloudDownload onClick={this.download.bind(this)}/>
          <DeleteForever onClick={this.delete.bind(this)}/>
        </div>
      </div>
    )
  }

  play(){
    this.props.play(this.props.pattern, this.ended.bind(this));
    this.setState({playing: 1})
  }

  stop(){
    this.props.stop();
  }

  ended(){
    this.setState({playing: 0})
  }

  download(){
    this.props.download(this.props.pattern, this.state.title);
  }

  delete(){
    this.props.delete(this.props.patternId);
  }
}

export default PlayCard;
