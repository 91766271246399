import React from 'react'

import './Blinker.css'

class Blinker extends React.Component {

  state = {
    on: false
  }

  render () {
    if(!this.props.visible)
      return null;

    let style = this.state.on ? {backgroundColor: 'var(--app-color)'} : null;

    return (
      <div style={style} id="blinker"></div>
    )
  }

  on(){
    this.setState({on: 1})
  }

  off(){
    this.setState({on: 0})
  }
}

export default Blinker;
