import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress';
import {Create, Sync} from '@material-ui/icons';

import NavButton from 'components/NavButton'
import PlayCard from 'components/PlayCard'
import ChangableName from 'components/ChangableName'
import ImgButton from 'components/ImgButton'

class PatternViewer extends React.Component {
  render () {
    let center = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }

    return (
      <React.Fragment>

        {
          this.props.collectionState === 'loading' ?
            <div style={{...center, marginTop: '50px'}}>
              <CircularProgress style={{color: 'var(--app-color)'}} />;
            </div>
          :
            null
        }

        {
          this.props.collectionState === 'error' ?
            <h2>Loading Error</h2>
          :
            null
        }

        {
          this.props.collectionState === 'loaded' ?
            <React.Fragment>
              <div style={{...center, justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '30px'}}>
                <ChangableName
                  name={this.props.collection.name}
                  onChange={this.props.onNameChange}
                  style={{maxWidth: '100%', marginRight: '20px'}}
                />
              <ImgButton
                  text='Sync device'
                  img={<Sync/>}
                  onClick={this.props.onSync}
                  style={{margin: '0px'}}
                />
              </div>

              {
                this.props.collection.patterns.length === 0 ?
                  <div style={{...center, flexDirection: 'column'}}>
                    <h2>No patterns found.</h2>
                    <NavButton
                      text="Create Now"
                      img={<Create/>}
                      localurl = "/create"
                    />
                  </div>
                :
                  <div className="playCards">
                    {this.props.collection.patterns.map(pattern =>
                        <PlayCard
                          patternId={pattern.id}
                          key={pattern.id}
                          title={pattern.name}
                          pattern={pattern.pattern}
                          play={this.props.onPlay}
                          stop={this.props.onStop}
                          delete={this.props.onDelete}
                          download={this.props.onDownload}
                        />
                      )
                    }
                  </div>
              }
            </React.Fragment>
          :
            null
        }

      </React.Fragment>
    )
  }
}

export default PatternViewer;
