import React from 'react'

import Spinner from 'components/Spinner'

import './CollectionStateViewer.css'

class CollectionStateViewer extends React.Component {
  render () {
    return (
      <div className="collectionState">
        <h2>{this.props.title}</h2>
        {
          this.props.collection.state === 'loaded' ?
            <React.Fragment>
              <h3>Name</h3>
              <ul><li>{this.props.collection.name}</li></ul>
              <h3>Patterns</h3>
              <ul>
                {
                  this.props.collection.patterns.map(pattern =>
                    <li key={pattern.id}>{pattern.name}</li>
                  )
                }
              </ul>
            </React.Fragment>
          :
            this.props.collection.state === 'loading' ?
              <React.Fragment>
                <h3>Name</h3>
                <ul><Spinner/></ul>
                <h3>Patterns</h3>
                <ul><Spinner/></ul>
              </React.Fragment>
            :
              this.props.collection.state === 'error' ?
                <h2 style={{border: 'none'}}>Loading error</h2>
              :
                this.props.collection.state === 'empty' ?
                  <h2 style={{border: 'none'}}>Empty</h2>
                :
                  null
        }

      </div>
    )
  }
}

export default CollectionStateViewer;
