import React from 'react'

import './Title.css'

class Title extends React.Component {
  render () {
    return (
      <section className="title">
        <h1>{this.props.title}</h1>
        <span>{this.props.text}</span>
      </section>
    )
  }
}

export default Title;
