import React from 'react'
import { NavLink } from "react-router-dom";
import { Home, Create, Vibration } from '@material-ui/icons';

import './Header.css';

class Header extends React.Component {
  render () {
    let iconStyle = {color: 'inherit'};
    return (
      <div id="header">

        <NavLink to="/"><h2>Vibrationcreator</h2></NavLink>

        <div id="navigation">

          <NavLink isActive={this.checkActive} to="/" activeClassName="active">
            <Home style={iconStyle}/>
            <span>Home</span>
          </NavLink>

          <NavLink to="/create" activeClassName="active">
            <Create style={iconStyle}/>
            <span>Create</span>
          </NavLink>

          <NavLink to="/play" activeClassName="active">
            <Vibration style={iconStyle}/>
            <span>play</span>
          </NavLink>
          
        </div>
      </div>
    )
  }

  checkActive = (match, location) => {
    if(!location) return false;
    const {pathname} = location;
    return pathname === "/";
  }
}

export default Header;
