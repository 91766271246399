import React from 'react'

import firebase from 'firebase/app'
import 'firebase/database'

import cookie from 'react-cookies'

import PlayTypeToggle from 'components/PlayTypeToggle'
import Blinker from 'components/Blinker'
import PatternDownloader from 'components/PatternDownloader'
import CollectionViewer from 'components/CollectionViewer'
import DeviceSyncronizer from 'components/DeviceSyncronizer'

import AudioPlayer from 'components/player/audioPlayer'
import VibrationPlayer from 'components/player/vibrationPlayer'
import BlinkPlayer from 'components/player/blinkPlayer'

import './Play.css'

class Play extends React.Component {

  state = {
    collectionState: 'loading',
    collection: [],
    collectionId: cookie.load('collectionId'),
    playType: '',
    downloadDialogOpen: false,
    deviceSyncronizerOpen: false,
    pattern: null,
    patternTitle: null,
  }

  componentDidMount(){
    this.db = firebase.database();

    this.collectionRef = this.db.ref('collections/'+this.state.collectionId);

    this.collectionRef.on('value', snapShot =>{
      let rawData = snapShot.val();
      let collection = {
        name: 'New Collection',
        id: snapShot.key,
        patterns: []
      }

      if(rawData){
        collection.name = rawData.name ? rawData.name : 'New Collection';

        for(let id in rawData.patterns){
          collection.patterns.push({
            id: id,
            ...rawData.patterns[id]
          })
        }
      }

      this.setState({collection: collection, collectionState: 'loaded'})
    },
    error =>{
      this.setState({collectionState: 'error'})
    })

    this.audioPlayer = new AudioPlayer();
    this.vibrationPlayer = new VibrationPlayer();
    this.blinkPlayer = new BlinkPlayer(this.refs['blinker']);

    this.changePlayType('audio')

    this.endedCallback = null;
  }

  render () {
    return (
      <div className="view">

        <h1>Play</h1>

        <PlayTypeToggle
          changePlayType={this.changePlayType.bind(this)}
          playType={this.state.playType}
        />

        <Blinker visible={this.state.playType === 'blink'} ref="blinker"/>

        <PatternDownloader
          open={this.state.downloadDialogOpen}
          onClose={this.closeDownload.bind(this)}
          pattern={this.state.pattern}
          title={this.state.patternTitle}
        />

        <DeviceSyncronizer
          open={this.state.deviceSyncronizerOpen}
          onClose={this.closeSync.bind(this)}
          collectionId={this.state.collectionId}
        />

        <CollectionViewer
            collection={this.state.collection}
            collectionState={this.state.collectionState}
            onPlay={this.play.bind(this)}
            onStop={this.stop.bind(this)}
            onDelete={this.delete.bind(this)}
            onDownload={this.openDownload.bind(this)}
            onNameChange={this.changeCollectionName.bind(this)}
            onSync={this.openSync.bind(this)}
          />

      </div>
    )
  }

  async play(pattern, endedCallback){
    this.stop();
    this.endedCallback = endedCallback;
    await this.player.play(pattern);
    this.stop();
  }

  stop(){
    if(this.endedCallback){
      this.endedCallback();
      this.endedCallback = null;
      this.player.stop();
    }
  }

  delete(patternId){
    this.stop();
    this.db.ref(`collections/${this.state.collectionId}/patterns/${patternId}`).remove()
  }

  changeCollectionName(name){
    this.db.ref(`collections/${this.state.collectionId}`).update({
      name: name
    })
  }

  openDownload(pattern, title){
    this.setState({pattern: pattern, downloadDialogOpen: true, patternTitle: title})
  }

  closeDownload(){
    this.setState({pattern: null, downloadDialogOpen: false, patternTitle: null})
  }

  openSync(){
    this.setState({deviceSyncronizerOpen: true})
  }

  closeSync(){
    this.setState({deviceSyncronizerOpen: false})
  }

  changePlayType(playType){
    this.stop();
    if(playType === 'audio')
      this.player = this.audioPlayer;
    else if(playType === 'vibrate')
      this.player = this.vibrationPlayer;
    else if (playType === 'blink') {
      this.player = this.blinkPlayer;
    }
    this.setState({playType: playType});
  }

  componentWillUnmount() {
    this.collectionRef.off();
  }
}

export default Play;
