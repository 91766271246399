import React from 'react'

import QRCode from 'qrcode.react'
import {Smartphone, DesktopWindows} from '@material-ui/icons';

import Dialog from 'components/Dialog'
import ImgButton from 'components/ImgButton'

import './DeviceSyncronizer.css'

class DeviceSyncronizer extends React.Component {
  state = {
    view: 'phone'
  }
  render () {
    let link = `${window.location.origin}/sync/${this.props.collectionId}`;
    return (
      <Dialog id="deviceSyncronizer" open={this.props.open} onClose={this.props.onClose} name="Sync device">

        {
          this.state.view === 'phone' ?
            <div className="holder">
              <QRCode size={200} value={link} />
            </div>
          :
            <div className="holder">
              <textarea readOnly value={link}></textarea>
            </div>
        }

        <div id="syncButtons">
          <ImgButton
            text="Phone"
            img={<Smartphone/>}
            onClick={this.changeView.bind(this,'phone')}
            isActive={this.state.view === 'phone'}
            style={{margin: '10px 20px'}}
          />
          <ImgButton
            text="Computer"
            img={<DesktopWindows/>}
            onClick={this.changeView.bind(this,'computer')}
            isActive={this.state.view === 'computer'}
            style={{margin: '10px 20px'}}
          />
        </div>




      </Dialog>
    )
  }

  changeView(view){
    this.setState({view: view});
  }
}

export default DeviceSyncronizer;
