import React from 'react'

class PatternVisualizer extends React.Component {

  componentDidMount() {
    this.canvas = document.getElementById('patternVisualizerCanvas');
    this.setCanvasDimensions();
    window.onresize = e =>{
      this.setCanvasDimensions.bind(this);
      this.paint();
    }
    this.ctx =  this.canvas.getContext("2d");

    this.canvasTimer = 0;
    this.lastTime = 0;
    this.reset();
  }

  render () {
    return (
      <canvas style={{width:'100%', border: 'solid white 3px', borderRadius: '10px'}} id="patternVisualizerCanvas"></canvas>
    );
  }

  press(){
    if(this.instructions[this.instructions.length-1]){
      if(this.instructions[this.instructions.length-1][0] === 'horDown')
        this.instructions[this.instructions.length-1][2] = this.canvasTimer+this.canvas.width/2;
    }
    this.instructions.push(['vert', this.canvasTimer+this.canvas.width/2]);
    this.instructions.push(['horUp', this.canvasTimer+this.canvas.width/2, 'cur']);
  }

  release(){
    if(this.instructions[this.instructions.length-1]){
      if(this.instructions[this.instructions.length-1][0] === 'horUp')
        this.instructions[this.instructions.length-1][2] = this.canvasTimer+this.canvas.width/2;
    }
    this.instructions.push(['vert', this.canvasTimer+this.canvas.width/2]);
    this.instructions.push(['horDown', this.canvasTimer+this.canvas.width/2, 'cur']);
  }

  paint(time){
    if(this.running)
      requestAnimationFrame(this.paint.bind(this));
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawBars();
    this.drawLine();

    if(!time)
      return;

    if(!this.lastTime) this.lastTime = time;
    this.canvasTimer += (time - this.lastTime) * 0.3;
    this.lastTime = time;
  }

  drawBars(){
    this.ctx.beginPath();
    let numBars = Math.round(this.canvas.width/200);
    for(let i = 0; i <= numBars; i++){
      let offset = (this.canvas.width/numBars)*i;
      let x = this.canvas.width - (this.canvasTimer+offset)%this.canvas.width;
      this.ctx.moveTo(x, 0);
      this.ctx.lineTo(x, this.canvas.height);
    }
    this.ctx.strokeStyle = "white";
    this.ctx.lineWidth = 3;
    this.ctx.stroke();
  }

  drawLine(){
    this.ctx.beginPath();
    let up = this.canvas.height/10;
    let down = this.canvas.height - up;

    for(let instruction of this.instructions){

      let x1,x2;
      switch(instruction[0]){
        case 'vert':
            x1 = instruction[1]-this.canvasTimer;
            this.ctx.moveTo(x1, down);
            this.ctx.lineTo(x1, up);
          break;
        case 'horUp':
            x1 = instruction[1] - this.canvasTimer;
            x2 = (instruction[2] === 'cur') ? this.canvas.width/2 : instruction[2] - this.canvasTimer;
            this.ctx.moveTo(x1, up);
            this.ctx.lineTo(x2, up);
          break;
        case 'horDown':
            x1 = instruction[1] - this.canvasTimer;
            x2 = (instruction[2] === 'cur') ? this.canvas.width/2 : instruction[2] - this.canvasTimer;
            this.ctx.moveTo(x1, down);
            this.ctx.lineTo(x2, down);
          break;
        default:
          console.error('unknown instruction')
      }
    }

    this.ctx.strokeStyle = getComputedStyle(document.body).getPropertyValue('--app-color');
    this.ctx.lineWidth = this.canvas.height/17;
    this.ctx.lineCap = 'round';
    this.ctx.stroke();
  }

  setCanvasDimensions(){
    this.canvas.width = this.canvas.getBoundingClientRect()['width'];
    this.canvas.height = this.canvas.width/20;
    if(this.canvas.height <= 70)
      this.canvas.height = 70;
    if(this.canvas.height >= 200)
      this.canvas.height = 200;
  }



  reset(){
    this.instructions = [['horDown', 0, 'cur']];
    this.stop();
    this.paint();
  }

  stop(){
    this.running = 0;
  }

  start(){
    this.running = 1;
    this.lastTime = null;
    this.paint();
  }

  componentWillUnmount(){
    this.stop();
    window.onresize = null;
  }

}

export default PatternVisualizer;
