import React from 'react'

import './ImgButton.css'

class ImgButton extends React.Component {
  render () {
    let isChildClass = this.props.isChild ? 'isChild' : '';
    let isActiveClass = this.props.isActive ? 'active' : ''

    return (
      <button style={this.props.style} onClick={this.props.onClick} className={`imgButton ${isChildClass} ${isActiveClass}`}>
        {this.props.img}
        <span>
          {this.props.text}
          <div className="background"></div>
        </span>
      </button>
    )
  }
}

export default ImgButton;
