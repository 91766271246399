import React from 'react'

import {Home} from '@material-ui/icons'

import Title from 'components/Title'
import ImgButton from 'components/ImgButton'

class Error404 extends React.Component {
  render () {
    return(
      <div className="view">
        <Title
          title="Error 404"
          text="Ooops this page couldn't be found"
        />

      <div style={{display: 'flex', justifyContent: 'center', margin: '40px 0px'}}>
          <ImgButton
            style={{margin: '0px'}}
            text="Bring me back home"
            img={<Home/>}
            localurl = "/"
          />
        </div>



      </div>
    )
  }
}

export default Error404;
