import React from 'react';
import ReactDOM from 'react-dom';

import firebase from 'firebase/app'

import { BrowserRouter } from "react-router-dom";

import App from './App';
import ScrollToTop from 'components/ScrollToTop'

import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
    apiKey: "AIzaSyAm7KfHDO0ohrB6Qc_lALmtOWZxxz-mOWo",
    authDomain: "vibrate-d7453.firebaseapp.com",
    databaseURL: "https://vibrate-d7453.firebaseio.com",
    projectId: "vibrate-d7453",
    storageBucket: "vibrate-d7453.appspot.com",
    messagingSenderId: "878294065689",
    appId: "1:878294065689:web:7755a9931bbbfd0c"
  };

firebase.initializeApp(firebaseConfig)

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>
, document.getElementById('root'));

serviceWorker.register();
