import React from 'react'

import {Close} from '@material-ui/icons';

import './Dialog.css'

class Dialog extends React.Component {
  render () {
    let openClass = this.props.open ? 'open' : '';
    return(
      <div id={this.props.id} className={'dialog '+openClass}>
        <div>

          <div className="dialogTitle">
            <h2>{this.props.name}</h2>
            <Close onClick={this.props.onClose}/>
          </div>

          {this.props.children}
          
        </div>
      </div>
    )
  }
}

export default Dialog;
